import {
  ArrowLeftOnRectangleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import { Link } from "@remix-run/react";
import Logo from "assets/logo.png";
import ProfileAvatarImg from "../../assets/profile_avatar.png";

export default function Nav({ userId }: { userId: null | string | undefined }) {
  // alert(JSON.stringify(data))
  return (
    <div className="navbar">
      <div className="navbar-start">
        <Link to="/">
          <img src={Logo} alt="logo" className="w-[150px] h-[75px]" />
        </Link>
      </div>
      <div className="navbar-end">
        {userId && (
          <div className="dropdown dropdown-end dropdown-hover">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src={ProfileAvatarImg} alt="" />
              </div>
            </label>

            <ul className="dropdown-content z-[1] menu p-2 shadow bg-base-200 rounded-box w-52">
              <li>
                <Link to="/settings">
                  <Cog6ToothIcon className="w-5 h-5" />
                  הגדרות
                </Link>
                <Link to="/logout">
                  <ArrowLeftOnRectangleIcon className="w-5 h-5" />
                  התנתק
                </Link>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
