import { useMatches } from "@remix-run/react";

export default function BreadCrumb() {
  const matches = useMatches();
  const crumbs = matches.filter((match: any) => match?.handle?.breadcrumb);
  if (crumbs.length === 1) {
    return <div className="py-5"></div>;
  }

  return (
    <div className="text-sm breadcrumbs pr-8 pt-3">
      <ul>
        {crumbs.map((c: any, index) => (
          <li key={c.pathname} className="inline-flex items-center">
            <div className=" text-sm text-base-content font-light hover:text-blue-600 ">
              <div
                className={`${index === crumbs.length - 1 ? "font-bold" : ""}`}
              >
                {c.handle && <c.handle.breadcrumb {...c.data} />}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
