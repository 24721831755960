import { cssBundleHref } from "@remix-run/css-bundle";
import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs } from
"@remix-run/node";
import {
  isRouteErrorResponse,
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigate,
  useRouteError } from
"@remix-run/react";
// TailWind
import { useEffect } from "react";
import stylesheet from "~/tailwind.css";
import BreadCrumb from "./components/BreadCrumb";
import { GlobalLoading } from "./components/GlobalLoading";
import Layout from "./components/Layout";
import Nav from "./components/Nav";
import { getUser } from "./lib/auth.server";
import { db } from "./lib/db.server";
import log from "./lib/log";

export const links: LinksFunction = () => [
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
{
  rel: "prefetch stylesheet",
  href: stylesheet,
  as: "style",
  type: "text/css"
},
{
  rel: "preconnect stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
  as: "font"
}];


export const handle = {
  breadcrumb: (data: any) => {
    return <Link to={`/`}>ראשי</Link>;
  }
};

export async function loader({ request }: LoaderFunctionArgs) {
  log.debug("root loader");
  const userId = await getUser(request);
  const projects = await db.project.findMany();
  return json({ projects, userId });
}

export default function App() {
  const { userId } = useLoaderData<typeof loader>();
  return (
    <html lang="en" data-theme="black">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <GlobalLoading />
        <Nav userId={userId} />
        <BreadCrumb />
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error: any = useRouteError();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isRouteErrorResponse(error)) {
      fetch(location.pathname, {
        body: JSON.stringify({ error: JSON.stringify(error) }),
        method: "POST",
        headers: { "Content-Type": "application/json" }
      });
    }
    console.error("error =>", error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isRouteErrorResponse(error)) {
    return (
      <html>
        <head>
          <title>שגיאה</title>
          <Meta />
          <Links />
        </head>
        <body>
          <Layout>
            <Nav userId="" />
            <span className="text-4xl text-error">שגיאה {error.status}</span>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-primary mt-5">

              חזור למסך הקודם
            </button>
          </Layout>
          <Scripts />
        </body>
      </html>);

  } else if (error instanceof Error) {
    return (
      <html>
        <head>
          <title>שגיאה</title>
          <Meta />
          <Links />
        </head>
        <body>
          <Layout>
            <Nav userId="" />
            <span className="text-4xl text-error">שגיאה</span>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-primary mt-5">

              חזור למסך הקודם
            </button>
          </Layout>
          <Scripts />
        </body>
      </html>);

  } else {
    return <h1>Unknown Error</h1>;
  }
}